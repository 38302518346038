.element-wrapper {
    padding-bottom: 0.5rem !important;
}
.nav-item.nav-actions {
    padding-top: 5px;
    margin-left: auto;
}
.nicescroll-cursors {
    background-color: rgb(240, 45, 45) !important;
}
.box-style-dark, .color-scheme-dark .ae-content-w .aec-full-message-w .aec-full-message, .color-scheme-dark .ae-content-w .aec-reply, body.color-scheme-dark .box-style, body.color-scheme-dark .user-profile, body.color-scheme-dark .element-box, body.color-scheme-dark .invoice-w, body.color-scheme-dark .big-error-w, body.color-scheme-dark .activity-boxes-w .activity-box, .activity-boxes-w body.color-scheme-dark .activity-box, body.color-scheme-dark .post-box, body.color-scheme-dark .projects-list .project-box, .projects-list body.color-scheme-dark .project-box, body.color-scheme-dark .profile-tile .profile-tile-box, .content-panel.color-scheme-dark .box-style, .content-panel.color-scheme-dark .user-profile, .content-panel.color-scheme-dark .element-box, .content-panel.color-scheme-dark .invoice-w, .content-panel.color-scheme-dark .big-error-w, .content-panel.color-scheme-dark .activity-boxes-w .activity-box, .activity-boxes-w .content-panel.color-scheme-dark .activity-box, .content-panel.color-scheme-dark .post-box, .content-panel.color-scheme-dark .projects-list .project-box, .projects-list .content-panel.color-scheme-dark .project-box, .content-panel.color-scheme-dark .profile-tile .profile-tile-box {
    background-color: #323c58;
    -webkit-box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
    box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#323c58), to(#303950));
    background-image: linear-gradient(to bottom, #323c58 0%, #303950 100%);
    background-repeat: repeat-x;
}
.element-box, .invoice-w, .big-error-w {
    padding: 1.5rem 2rem;
    margin-bottom: 1rem;
}
.box-style, .user-profile, .element-box, .invoice-w, .big-error-w, .activity-boxes-w .activity-box, .post-box, .projects-list .project-box {
    border-radius: 6px;
    background-color: #0f0d2d;
    -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
    box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
}
.element-box, .invoice-w, .big-error-w {
    -webkit-animation-name: fadeUp;
    animation-name: fadeUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}
.element-wrapper .element-info {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}
.element-wrapper .element-info .element-info-with-icon {
    margin-bottom: 0px;
}
.element-wrapper .element-info-with-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2rem;
}
    .element-wrapper .element-info-with-icon .element-info-icon {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70px;
        flex: 0 0 70px;
        color: #047bf8;
        font-size: 30px;
    }
body.color-scheme-dark .b-r, body.color-scheme-dark .b-b, body.color-scheme-dark .b-t, body.color-scheme-dark .b-l, .content-panel.color-scheme-dark .b-r, .content-panel.color-scheme-dark .b-b, .content-panel.color-scheme-dark .b-t, .content-panel.color-scheme-dark .b-l {
    border-color: rgba(255, 255, 255, 0.05);
}

.b-b {
    border-bottom: 1px solid #2af270;
}
body.color-scheme-dark .el-tablo .value, .content-panel.color-scheme-dark .el-tablo .value {
    color: #fff;
}

.el-tablo .value {
    font-size: 2.43rem;
    font-weight: 500;
    font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    letter-spacing: 1px;
    line-height: 1.2;
    display: inline-block;
    vertical-align: middle;
    color: white;
}
.b-r {
    border-right: 1px solid #2af270;
}
.el-tablo.centered.padded {
    padding-left: 10px;
    padding-right: 10px;
}

.el-tablo.padded {
    padding: 2rem;
}

.el-tablo.centered {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.padded {
    padding: 1rem 2rem;
}
.element-wrapper .element-info {
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
}
.el-tablo {
    display: block;
}
body.color-scheme-dark .el-tablo .value, .content-panel.color-scheme-dark .el-tablo .value {
    color: #fff;
}
body.color-scheme-dark .el-tablo .label, .content-panel.color-scheme-dark .el-tablo .label {
    color: rgba(218, 226, 243, 0.4);
}
.el-tablo .label {
    display: block;
    font-size: 0.63rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    letter-spacing: 1px;
}
.el-tablo.centered.padded {
    padding-left: 10px;
    padding-right: 10px;
}
.el-tablo.padded {
    padding: 2rem;
}
.el-tablo.centered.padded {
    padding-left: 10px;
    padding-right: 10px;
}
.el-tablo .label {
    display: block;
    font-size: 0.63rem;
    text-transform: uppercase;
    color: rgb(206 221 241);
    letter-spacing: 1px;
}
.element-wrapper .element-info-with-icon.smaller .element-info-text .element-inner-header {
    margin-bottom: 0px;
}
body.color-scheme-dark .el-tablo .value, .content-panel.color-scheme-dark .el-tablo .value {
    color: #fff;
}

.element-wrapper .element-inner-header {
    margin-bottom: 0.5rem;
    margin-top: 0px;
    display: block;
}
body.color-scheme-dark .element-wrapper .element-inner-desc, .content-panel.color-scheme-dark .element-wrapper .element-inner-desc {
    color: #ccd9e8;
}
.content-box {
    vertical-align: top;
    padding: 2rem 2.5rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.element-wrapper .element-inner-desc {
    color: white;
    font-weight: 300;
    font-size: 0.81rem;
    display: block;
}
.os-progress-bar {
    margin-bottom: 1rem;
}
    .os-progress-bar .bar-labels {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .os-progress-bar .bar-label-left span {
        margin-right: 5px;
    }

    .os-progress-bar .bar-labels span {
        font-size: 0.72rem;
    }
body.color-scheme-dark .os-progress-bar .bar-label-right span.info, .content-panel.color-scheme-dark .os-progress-bar .bar-label-right span.info {
    color: #7281b7;
}

.os-progress-bar .bar-label-right span.info {
    color: #55aac7;
}

.os-progress-bar .bar-label-right span {
    margin-left: 5px;
}
.os-progress-bar .bar-labels span {
    font-size: 0.72rem;
}
body.color-scheme-dark .os-progress-bar.blue .bar-level-1, body.color-scheme-dark .os-progress-bar.primary .bar-level-1, body.color-scheme-dark .os-progress-bar.warning .bar-level-1, body.color-scheme-dark .os-progress-bar.info .bar-level-1, body.color-scheme-dark .os-progress-bar.danger .bar-level-1, body.color-scheme-dark .os-progress-bar.success .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.blue .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.primary .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.warning .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.info .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.danger .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.success .bar-level-1 {
    background-color: #293148;
}

.os-progress-bar.blue .bar-level-1, .os-progress-bar.primary .bar-level-1 {
    background-color: #F2F2F2;
}

.os-progress-bar .bar-level-1, .os-progress-bar .bar-level-2, .os-progress-bar .bar-level-3 {
    border-radius: 12px;
    height: 6px;
}
body.color-scheme-dark .os-progress-bar.blue .bar-level-2, body.color-scheme-dark .os-progress-bar.primary .bar-level-2, .content-panel.color-scheme-dark .os-progress-bar.blue .bar-level-2, .content-panel.color-scheme-dark .os-progress-bar.primary .bar-level-2 {
    background-color: #3870ef;
}

.os-progress-bar.blue .bar-level-2, .os-progress-bar.primary .bar-level-2 {
    background-color: #65affd;
}

.os-progress-bar .bar-level-1, .os-progress-bar .bar-level-2, .os-progress-bar .bar-level-3 {
    border-radius: 12px;
    height: 6px;
}
body.color-scheme-dark .b-r, body.color-scheme-dark .b-b, body.color-scheme-dark .b-t, body.color-scheme-dark .b-l, .content-panel.color-scheme-dark .b-r, .content-panel.color-scheme-dark .b-b, .content-panel.color-scheme-dark .b-t, .content-panel.color-scheme-dark .b-l {
    border-color: #2af270;
}
@media (min-width: 1150px) {
    .content-box {
        width: 0 !important;
    }
}
@media (min-width: 1200px) {
    .b-r-xl {
        border-right: 1px solid #2af270;
    }
}
.b-l {
    border-left: 1px solid #2af270;
}
.with-side-panel .content-i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.element-wrapper .element-header {
    border-bottom: 1px solid rgb(37 35 64);
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
}
body.color-scheme-dark .element-wrapper .element-header, .content-panel.color-scheme-dark .element-wrapper .element-header {
    border-bottom-color: #2af270;
}
.element-wrapper .element-header:after {
    content: "";
    background-color: #2af270;
    width: 25px;
    height: 4px;
    border-radius: 0px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0px;
}
.el-chart-w {
    position: relative;
    text-align: center;
}
.chartjs-size-monitor, .chartjs-size-monitor-expand, .chartjs-size-monitor-shrink {
    position: absolute;
    direction: ltr;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
}
.el-chart-w .inside-donut-chart-label {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 0.63rem;
    text-transform: uppercase;
    z-index: 1;
}
body.color-scheme-dark .el-chart-w .inside-donut-chart-label strong, .content-panel.color-scheme-dark .el-chart-w .inside-donut-chart-label strong {
    color: #fff;
}
body.color-scheme-dark .el-chart-w + .el-legend, .content-panel.color-scheme-dark .el-chart-w + .el-legend {
    border-top-color: rgba(255, 255, 255, 0.05);
}

.el-legend.condensed {
    margin-top: 15px;
}

.el-chart-w + .el-legend {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1.5rem;
    margin-top: 2rem;
}
.el-legend.condensed .legend-value-w {
    white-space: nowrap;
}
.el-legend .legend-value-w {
    margin-bottom: 0.5rem;
}
.el-legend.condensed .legend-value-w .legend-pin {
    vertical-align: top;
    margin-top: 3px;
    margin-right: 2px;
}